import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APPLICATION_ROLES } from "services/endPoints";
import { privateRequest } from "services/privateRequest";

const initialState = {
  loading: false, //Button Loader
  topBarDropDownData: {
    open: false,
    data: null,
  },
  openCreateIssue: false,
  sidebarOpen: true,
  openDeleteStage: false,
  projectMoreIconDropDown: {
    open: false,
    data: null,
  },
  applicationRoleList: []
};

export const getApplicationRoleList = createAsyncThunk(
  "entity/getApplicationRoleList",
  async () => {
    const res = await privateRequest.get(APPLICATION_ROLES);
    return {
      data: res?.data?.data || [],
    };
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getApplicationRoleList.fulfilled, (state, action) => {
      state.applicationRoleList = action.payload.data;
    });
  },
  reducers: {
    setSidebarOpen: (state, action) => {
      state.sidebarOpen = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTopBarDropDownData: (state, action) => {
      state.topBarDropDownData = action.payload;
    },
    setProjectMoreIconDropDown: (state, action) => {
      state.projectMoreIconDropDown = action.payload;
    },
    setOpenCreateIssue: (state, action) => {
      state.openCreateIssue = action.payload;
    },
    setOpenDeleteStage: (state, action) => {
      state.openDeleteStage = action.payload;
    },
  },
});

export default globalSlice.reducer;
export const {
  setSidebarOpen,
  setLoading,
  setTopBarDropDownData,
  setOpenCreateIssue,
  setOpenDeleteStage,
  setProjectMoreIconDropDown,
} = globalSlice.actions;
