import React, { memo } from 'react';
import { Navigate} from 'react-router-dom';
import { PATH } from 'utils/pagePath';
import { getToken } from 'utils/utils';

const PublicRoute = ({ component: Component }) => {
    return (
        !getToken() ? Component : <Navigate to={PATH.PROJECTS} /> 
    )
}
 
export default memo(PublicRoute);
