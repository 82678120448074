export const PATH={
    DEFAULT: "/",
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    FORGOT_PASSWORD: '/forgot-password',
    REGISTER: '/register',
    OTP_VERIFICATION: '/otp-verification',
    CONFIRM_PASSWORD: '/confirm-password',
    SET_PASSWORD: '/set-password',
    INVITATION: '/invitation',
    PROJECT_PLANNING: '/project-planning',
    BACKLOG: '/project-planning/backlog',
    ACTIVE_SPRINT: '/project-planning/active-sprint',
    ACTIVE_SPRINT_DETAILS: '/project-planning/active-sprint/details',
    DEVELOPMENT: '/development',
    CODE: '/development/code',
    PROJECTS: '/projects',
    CREATE_PROJECTS: '/projects/create-projects',
    PROJECT_SETTINGS: '/project-settings',
    PROFILE_VIEWPROFILE:'/profile-viewprofile',
}