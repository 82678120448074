import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./utils/global.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { router } from "router";
import App from "./App";
import store from "store/store";
import { Toaster } from "react-hot-toast";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Toaster
      position="top-right"
      reverseOrder={false}
      toastOptions={{
        style: {
          fontSize: "14px",
        },
      }}
    />
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <App />
      </LocalizationProvider>
    </Provider>
  </>

);

reportWebVitals();
