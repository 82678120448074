import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { UPLOAD_PROFILE,USER_PROFILE } from "services/endPoints";
import { privateRequest } from "services/privateRequest";

const initialState = {
    uploadProfileResp: {},
    loading: false,
    loader: false,
    userInfoData :{}

  };

  
export const uploadProfile = createAsyncThunk(
    "entity/uploadProfile",
    async (params) => {   
      let formData = new FormData();
      formData.append('file', params);
      let UPLOAD_PROFILE_URL  = process.env.REACT_APP_API_BASE_URL + UPLOAD_PROFILE 
      const res = await axios.post(UPLOAD_PROFILE_URL, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      return {
        data: res?.data,
      };
    }
  );
  export const getUserProfileInfo = createAsyncThunk(
    "entity/getUserProfileInfo",
    async (params) => {
      const res = await privateRequest.get(`${USER_PROFILE}/${params.id}`);
      return {
        data: res?.data,
      };
    }
  );
  export const editUserProfileInfo = createAsyncThunk(
    "entity/editUserProfileInfo",
    async (params) => {
      const res = await privateRequest.put(`${USER_PROFILE}/${params.id}`,params);
      return {
        data: res?.data,
      };
    }
  );

  const profileSlice = createSlice({
    name: "Profile",
    initialState,
    reducers: {
      setLoading: (state, action) => {
        state.loading = action.payload;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(uploadProfile.pending, (state, action) => {
          state.loader = true;
        })
        .addCase(uploadProfile.fulfilled, (state, action) => {
          state.uploadProfileResp = action.payload.data;
          state.loader = false;
          state.loading = false;
        })
        .addCase(uploadProfile.rejected, (state, action) => {
          state.loader = false;
        })
        .addCase(getUserProfileInfo.pending, (state, action) => {
          state.loader = true;
        })
        .addCase(getUserProfileInfo.fulfilled, (state, action) => {
          state.userInfoData = action.payload.data
          state.loader = false;
          state.loading = false;
        })
        .addCase(getUserProfileInfo.rejected, (state, action) => {
          state.loader = false;
        })
        .addCase(editUserProfileInfo.pending, (state, action) => {
          state.loader = true;
        })
        .addCase(editUserProfileInfo.fulfilled, (state, action) => {
          state.loader = false;
          state.loading = false;
        })
        .addCase(editUserProfileInfo.rejected, (state, action) => {
          state.loader = false;
        })
      
    },
   
  });

  export default profileSlice.reducer;
