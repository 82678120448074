import axios from "axios";
import toast from "react-hot-toast";

export const combineClasses = (...classes) => {
  return classes.join(" ");
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const getProjectId = () => {
  return localStorage.getItem("projectId")!='undefined' ? JSON.parse(localStorage.getItem("projectId")) : localStorage.getItem("projectId");
};

export const getProjectKey = () => {
  return localStorage.getItem("projectKey");
};

export const getProjectName = () => {
  return localStorage.getItem("projectName");
};

export const getProjectIcon = () => {
  return localStorage.getItem("projectIcon");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const getUserName = () => {
  return localStorage.getItem("username");
};

export const getUserProfileUrl = () => {
  return localStorage.getItem("profileUrl");
};

export const getUserProfileColor = () => {
  return localStorage.getItem("profileColour");
};

export const onlyNumbersInput = (e) => {
  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
};

export const getColor = () => {
  function hslToHex(h, s, l) {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, "0"); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }
  return hslToHex(
    360 * Math.random(),
    25 + 70 * Math.random(),
    85 + 10 * Math.random()
  );
};

export const getPdfPreviewUrl = async (setUrl, url) => {
  try{const res = await axios.get(url, {
    responseType: "blob",
  });
  const previewUrl = URL.createObjectURL(res?.data);
  setUrl(previewUrl);}catch(error){
    toast.error(error?.message)
  }
};
