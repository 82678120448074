import axios from "axios";
import toast from "react-hot-toast";
import { getToken } from "utils/utils";
import { REFRESH_TOKEN } from "./endPoints";
import { publicRequest } from "./publicRequest";

export const privateRequest = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const requestHandler = (request) => {
  const token = getToken() || "";
  request.headers.Authorization = `Bearer ${token}`;
  request.headers["x-user-role"] = localStorage.getItem("currentRole");
  request.headers["project-id"] = localStorage.getItem("projectId");
  return request;
};

const clearToken = () => {
  localStorage.removeItem("token");
};

// Define the structure of a retry queue item
const refreshAndRetryQueue = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const handleRefreshToken = async (error) => {
  const refreshToken = localStorage.getItem("refreshToken");
  const originalRequest = error.config;
  if (!isRefreshing) {
    isRefreshing = true;
    try {
      const response = await publicRequest.post(REFRESH_TOKEN, null, {
        params: { refreshToken },
      });
      const { accessToken } = response.data.data;
      localStorage.setItem("token", accessToken);
      window.location.reload()
      error.config.headers["Authorization"] = `Bearer ${accessToken}`;
      refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
        privateRequest
          .request(config)
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      });
      refreshAndRetryQueue.length = 0;
      return privateRequest(originalRequest);
    } catch (refreshError) {
      // throw refreshError;
      window.location.href = '/'
    } finally {
      isRefreshing = false;
    }
  }
  return new Promise((resolve, reject) => {
    refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
  });
};
// {
//   const refreshToken = localStorage.getItem("refreshToken");
//   const originalRequest = error.config;
//   if (!isRefreshing) {
//     isRefreshing = true;
//     try {
//       const response = await publicRequest.post(REFRESH_TOKEN, null, {
//         params: { refreshToken },
//       });
//       const { accessToken } = response.data.data;
//       localStorage.setItem("token", accessToken);
//       error.config.headers["Authorization"] = `Bearer ${accessToken}`;
//       refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
//         privateRequest
//           .request(config)
//           .then((response) => resolve(response))
//           .catch((err) => reject(err));
//       });
//       refreshAndRetryQueue.length = 0;
//       return privateRequest(  );
//     } catch (refreshError) {
//       throw refreshError;
//     } finally {
//       isRefreshing = false;
//     }
//   }
//   return new Promise((resolve, reject) => {
//     refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
//   });
// };

const responseErrorHandler = (error) => {
  if (error.response) {
    const { status, data, message } = error.response;

    switch (status) {
      case 401:
        clearToken();
        // window.location.href = "/";
        handleRefreshToken(error);
        // toast.warn("Token expired, please login");
        break;
      case 400:
        {
          toast.error(
            data.message
              ? data.message
              : message || "Invalid Value/ Bad Request"
          );
          return false;
        }
        break;
      case 403:
        toast.error(
          data.message ? data.message : message || "Access Denied/ Forbidden"
        );
        break;
      case 404:
        // toast.error(data.message ? data.message : message || "Item doesn't exist")
        break;
      case 405:
        toast.error(data.message ? data.message : message || "Invalid Request");
        break;
      case 422:
        toast.error(data.message ? data.message : message || "Already Exists");
        break;
      case 501:
        toast.error(data.message ? data.message : message || "Session Expired");
        break;
      case 504:
        toast.error(data.message ? data.message : message || "Network Error");
        break;
      default:
        toast.error(
          data.message ? data.message : message || "Some Error Occurred"
        );
        break;
    }
  } else {
    toast.error(error?.message || "Some Error Occurred");
  }
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

privateRequest.interceptors.request.use(requestHandler, errorHandler);

privateRequest.interceptors.response.use((response) => {
  return response;
}, responseErrorHandler);
