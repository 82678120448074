// import logo from "./logo.svg";
import { router } from "router";
import styles from "./App.module.scss";
import './App.css'
import { RouterProvider } from "react-router-dom";

function App() {
  return (
    <div className={styles.app}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
